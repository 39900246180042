@tailwind base;
@tailwind components;
@tailwind utilities;
/* Importing fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Lato:wght@400;700&family=Open+Sans:wght@400;700&display=swap');

html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.social-login .nsm7Bb-HzV7m-LgbsSe {
  height: 63px !important;
  font-size: 24px;
  margin-bottom: 30px !important;
}

.social-login > div {
  height: 63px !important;
}

.el-group .css-1im77uy-control {
  background-color: black !important;
  border-color: white !important;
  color: white !important;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
}

.el-group .ss-17w84bz-singleValue {
  background-color: black !important;
  border-color: white !important;
  color: white !important;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
}

.el-group .css-1im77uy-control .selected-logo {
  padding-right: 10px !important;
}

/* width */
/* ::-webkit-scrollbar {
    width: 20px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: #474747;
    border-radius: 10px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #474747;
} */

/* Slider Settings */

.slick-arrow.slick-next {
  right: 0px !important;
  display: none !important;
}

.slick-arrow.slick-prev {
  display: none !important;
}

.slick-slider {
  width: 100%;
  overflow: hidden;
}

.slick-list {
  position: relative;
  display: block !important;
  width: 100%;
}

.slick-slide div {
  width: auto;
  /* margin-right: 20px; */
}

.slick-slide img {
  width: calc(100% - 20px) !important;
}

/* Toastify Custom Style */

.Toastify__toast-theme--dark {
  /* background: rgb(39, 35, 35) !important */
}

.Toastify__progress-bar.Toastify__progress-bar-theme--dark {
  background-color: rgb(234 88 12) !important;
}

select {
  padding-right: 0px !important;
  margin-right: 50px;
}

#meet iframe {
  /* height: calc(100vh-100px) !important; */
  /* height: calc(100vh) !important; */
}

.leftwatermark {
  display: none !important;
  visibility: hidden !important;
  color: transparent !important;
}

select {
  padding: 10px;
  padding-right: 30px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'); */
  background-image: url("./assets/icons/dropdown-1.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0.4em top 50%, 0 0;
  background-size: 1.5em auto, 100%;
}

/* For IE (thanks to @SaiManoj) */
select::-ms-expand {
  display: none;
}

.uber-works-video #player {
  max-width: none !important;
}

.uber-works-video {
  position: relative;
  overflow: hidden;
  /* aspect-ratio: 677 / 366; */
}

@media only screen and (max-width: 1200px) {
  .uber-works-video {
    position: relative;
    overflow: hidden;
    padding-top: 0px;
  }

  .uber-works-video .vp-preview {
    height: 600px !important;
    margin-top: -100px;
  }
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  aspect-ratio: 677 / 366;
}

.video-container iframe {
  width: 100%;
  height: 700px;
  /* height: 100%; */
  object-fit: cover;
}

.general-login .react-tel-input input {
  width: 100%;
  height: 45px;
  margin-top: 5px;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
  border-color: rgb(29, 130, 225);
}

@media screen and (max-width: 375px) {
  html {
    font-size: 7px;
  }
}
@media screen and (min-width: 376px) {
  html {
    font-size: 8px;
  }
}
@media screen and (min-width: 640px) {
  html {
    font-size: 9px;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 10px;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 12px;
  }
}
@media screen and (min-width: 1280px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1536px) {
  html {
    font-size: 16px;
  }
}

.slider .animated {
  transform: translate3d(0%, 0px, 0px) !important;
}
